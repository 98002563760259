import Image from 'next/future/image';
import Link from 'next/link';
import { ReebeloStoreT } from '@lambda/reebelo';
import DollarIcon from '@/public/icons/menu/dollar.svg';
import settings from '@/settings';

const BuybackCta = ({
  closeMenuPanel,
}: {
  closeMenuPanel: (val?: boolean) => void;
}) => {
  if (settings.buyback_exist) {
    const endpoints: Partial<Record<ReebeloStoreT, string>> & {
      default: string;
    } = {
      quista: '/buyback/sell-electronic',
      'reebelo-us': '/buyback/sell-electronic',
      default: '/sell-phone',
    };

    const endpoint = endpoints[settings.store] || endpoints.default;

    return (
      <Link href={endpoint}>
        <button
          className="flex w-full items-center px-4 py-3 hover:bg-gray-300 focus:outline-none sm:px-5"
          onClick={() => {
            closeMenuPanel();
          }}
        >
          <Image
            src={DollarIcon}
            alt="Sell your device"
            height={22}
            width={22}
            className="ml-[1px] mr-3"
          />
          <span>Sell Your Device</span>
        </button>
      </Link>
    );
  }

  return null;
};

export default BuybackCta;
