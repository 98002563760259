import Image from 'next/future/image';
import Link from 'next/link';
import FaqIcon from '@/public/icons/menu/faq.svg';

const FaqCta = ({
  closeMenuPanel,
}: {
  closeMenuPanel: (val?: boolean) => void;
}) => (
  <Link href={'/help'}>
    <button
      className="flex w-full items-center px-4 py-3 hover:bg-gray-300 focus:outline-none sm:px-5"
      onClick={() => {
        closeMenuPanel();
      }}
    >
      <Image src={FaqIcon} alt="Help" height={25} width={25} className="mr-3" />
      <span>Help</span>
    </button>
  </Link>
);

export default FaqCta;
