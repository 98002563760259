/* eslint-disable react/forbid-dom-props */
import Link from 'next/link';
import {
  AccessoriesProps,
  CollectionCardProps,
} from '@/components/HeaderMenu/MenuPanel/types';
import { chunkArray } from '../utils';

const CollectionCard = ({ item, closeMenuPanel }: CollectionCardProps) => {
  const formattedList = item.links ? chunkArray(item.links, 3) : [];

  return (
    <div className="flex flex-col">
      <Link href={`/collections/${item.handle}`}>
        <a
          onClick={() => {
            closeMenuPanel();
          }}
          className="flex cursor-pointer text-sm font-bold hover:underline"
        >
          <h2>{item.menuTitle}</h2>
        </a>
      </Link>
      <table className="mt-3 table w-full table-fixed">
        <colgroup>
          <col />
          <col />
          <col />
        </colgroup>
        {formattedList.map((listItem) => (
          <tr>
            {listItem.map((subItem) => (
              <td>
                <Link
                  href={`/collections/${subItem.handle}`}
                  className="w-full"
                >
                  <a
                    className="block w-full py-1 hover:underline"
                    onClick={() => {
                      closeMenuPanel();
                    }}
                  >
                    {subItem.menuTitle}
                  </a>
                </Link>
              </td>
            ))}
          </tr>
        ))}
      </table>
    </div>
  );
};

const Accessories = ({ items, closeMenuPanel }: AccessoriesProps) => (
  <tr className="border-b last:border-b-0">
    <td className="p-5">
      <CollectionCard item={items[0]} closeMenuPanel={closeMenuPanel} />
    </td>
  </tr>
);

export default Accessories;
