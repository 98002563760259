/* eslint-disable tailwindcss/no-custom-classname */
import React, { Fragment, useEffect, useReducer } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Image from 'next/future/image';
import Link from 'next/link';
import cn from 'classnames';
import { isArray, isEmpty, isUndefined } from 'lodash-es';
import dayjs from 'dayjs';
import useSWR from 'swr';
import ChevronRight from '@/public/icons/menu/chevron-right.svg';
import ExternalWhiteIcon from '@/public/icons/menu/external-white.svg';
import ExternalBlackIcon from '@/public/icons/menu/external-black.svg';
import XIcon from '@/public/icons/menu/x.svg';
import defaultMenu from '@/settings/menus/DEFAULT.json';
import FireIcon from '@/public/icons/collection/fire.svg';
import * as Icons from '@/components/commons/Header/Icons';
import {
  FirstLevelMenuItemProps,
  FirstLevelPanelProps,
  MenuItem,
  MenuPanelProps,
  PreviousViewProps,
  SecondLevelPanelProps,
  StaticFirstLevelMenuItemProps,
} from '@/components/HeaderMenu/MenuPanel/types';
import BuybackCta from '@/components/HeaderMenu/MenuPanel/common/BuybackCta';
import AccountCta from '@/components/HeaderMenu/MenuPanel/common/AccountCta';
import FaqCta from '@/components/HeaderMenu/MenuPanel/common/FaqCta';
import Layout from '@/components/HeaderMenu/MenuPanel/layouts';
import Badge from '@/components/HeaderMenu/MenuPanel/common/Badge';
import useSubscribeFF from '@/lib/useSubscribeFF';
import smartphones from '@/public/images/menu/Smartphones.png';
import laptops from '@/public/images/menu/Laptops.png';
import smartwatches from '@/public/images/menu/Smartwatch.png';
import iPads from '@/public/images/menu/iPads.png';
import audio from '@/public/images/menu/Audio.png';
import kitchen from '@/public/images/menu/Kitchen.png';
import more from '@/public/images/menu/more.png';
import gaming from '@/public/images/menu/Gaming.png';
import accessories from '@/public/images/menu/access.png';
import outdoor from '@/public/images/menu/outdoor.png';
import baby from '@/public/images/menu/baby.png';
import ChristmasHat from '@/public/icons/logo/christmas_hat.png';
import { getPageUrl } from '@/components/collections/helpers';
import { customizeCollectionTitle } from '@/components/commons/Header/MenuBar/helpers';

const getMenuImg = (handle: string | undefined) => {
  if (!handle) return '';

  switch (handle) {
    case 'smartphones':
      return smartphones;
    case 'accessories':
      return accessories;
    case 'laptops':
      return laptops;
    case 'tablets':
      return iPads;
    case 'smartwatches':
      return smartwatches;
    case 'gaming':
      return gaming;
    case 'audio-home-entertainment':
      return audio;
    case 'outdoor-sports':
      return outdoor;
    case 'home-appliances':
      return kitchen;
    case 'baby':
      return baby;

    default:
      return more;
  }
};

const FirstLevelMenuItem: React.FC<FirstLevelMenuItemProps> = React.memo(
  ({
    menuItem,
    isExpandable = false,
    isExpanded = false,
    setSelected,
    selected,
    closeMenuPanel,
    selectedMobile,
  }) => {
    const shouldExpand = !(
      menuItem?.links?.length === 1 &&
      (menuItem?.links?.[0] as MenuItem)?.menuSectionHeader
    );

    return (
      <div className="px-4 py-2 hover:bg-gray-300 focus:outline-none sm:px-5">
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center">
            <div
              className={cn(
                'mr-3 flex h-[40px] w-[40px] items-center justify-center rounded-full bg-gray-500/50',
                {
                  'animate-pulse': !menuItem.imageUrl,
                },
              )}
            >
              {menuItem?.imageUrl && (
                <div className="flex h-[30px] w-[30px] items-center justify-center">
                  <span className="h-[30px] w-[30px]">
                    <Image
                      src={
                        (selectedMobile && selectedMobile.length) === 0
                          ? getMenuImg(menuItem.handle)
                          : menuItem.imageUrl
                      }
                      height={30}
                      width={30}
                      alt="Expand"
                      className="h-full w-[30px] object-contain"
                    />
                  </span>
                </div>
              )}
            </div>
            <h2 className="text-left font-bold">
              {menuItem.menuTitle}
              {menuItem?.badge && (
                <Badge label={menuItem.badge} className="ml-3 lg:hidden" />
              )}
            </h2>
          </div>
          {isExpandable && (
            <Image
              src={ChevronRight}
              height={14}
              width={14}
              alt="Expand"
              className={`transition-all duration-150 ease-in-out ${
                isExpanded && 'lg:rotate-90'
              }`}
            />
          )}
        </div>
        {isExpanded && shouldExpand && (
          <div className="hidden flex-col lg:flex">
            {menuItem?.links?.map((item: MenuItem) => {
              const isSelected = selected?.subMenu?.handle === item.handle;

              if (isArray(item?.links) && isEmpty(item?.links)) return null;

              if (isUndefined(item?.links)) {
                return (
                  <Link href={getPageUrl(item.handle)}>
                    <a
                      onClick={(e) => {
                        e.stopPropagation();

                        closeMenuPanel();
                      }}
                      className={cn(
                        'relative ml-[53px] flex items-center py-1 text-left transition duration-150 ease-in-out hover:font-bold focus:outline-none',
                        {
                          'font-bold': isSelected,
                          'font-light': !isSelected,
                        },
                        {
                          underline: item.menuSectionHeader,
                        },
                      )}
                    >
                      {customizeCollectionTitle(item.handle, item.menuTitle)}
                    </a>
                  </Link>
                );
              }

              return (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (typeof setSelected === 'function')
                      setSelected({ subMenu: item });
                  }}
                  className={cn(
                    'ml-[53px] py-1 text-left transition duration-150 ease-in-out hover:font-bold focus:outline-none',
                    {
                      'font-bold': isSelected,
                      'font-light': !isSelected,
                    },
                  )}
                >
                  {item.menuTitle}
                </button>
              );
            })}
          </div>
        )}
      </div>
    );
  },
);

const StaticFirstLevelMenuItem: React.FC<StaticFirstLevelMenuItemProps> =
  React.memo(({ details }) => (
    <div className="px-4 py-2 hover:bg-gray-300 focus:outline-none sm:px-5">
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center">
          <div
            className={cn(
              'mr-3 flex h-[40px] w-[40px] items-center justify-center rounded-full bg-gray-500/50 ',
              {
                'animate-pulse': !details.icon,
              },
            )}
          >
            {details?.icon && (
              <div className="flex h-[20px] w-[20px] items-center justify-center">
                <span className="h-[20px] w-[20px]">
                  <Image
                    src={details.icon}
                    height={20}
                    width={20}
                    alt="Expand"
                    className="h-full w-[20px] object-contain"
                  />
                </span>
              </div>
            )}
          </div>
          <h2 className="font-bold">{details.title}</h2>
        </div>
      </div>
    </div>
  ));

// Second Level Panel
const SecondLevelPanel: React.FC<SecondLevelPanelProps> = ({
  selectedSubMenu,
  closeMenuPanel,
}) => (
  <div className="pointer-events-auto relative h-[100dvh] w-full bg-white lg:max-w-[800px]">
    <div className="flex h-full flex-col">
      <div className="flex items-center justify-between border-b px-4 py-4 text-base font-semibold leading-6 sm:px-6">
        <h2>{selectedSubMenu.title}</h2>
        <div className="flex gap-2">
          {(selectedSubMenu.title === 'iPhones' ||
            selectedSubMenu.title === 'Samsung Phones') && (
            <Link
              href={`/collections/${
                selectedSubMenu.title === 'iPhones'
                  ? 'iphone-accessories'
                  : 'samsung-accessories'
              }`}
            >
              <a
                onClick={() => {
                  closeMenuPanel();
                }}
                type="button"
                className="group flex h-7 items-center justify-center rounded-full border border-gray-700 !bg-white px-4 text-xs font-normal transition duration-150 ease-in-out  focus:outline-none"
              >
                <span>
                  {selectedSubMenu.title === 'iPhones'
                    ? 'iPhone Accessories'
                    : 'Samsung Accessories'}
                </span>
              </a>
            </Link>
          )}
          <Link href={`/collections/${selectedSubMenu.handle}`}>
            <a
              onClick={() => {
                closeMenuPanel();
              }}
              type="button"
              className="group flex h-7 items-center justify-center rounded-full border border-gray-700 !bg-gray-700 px-4 text-xs font-normal text-white transition duration-150 ease-in-out hover:!bg-white hover:text-gray-700 focus:outline-none"
            >
              <span>View All Products</span>
              <Image
                src={ExternalWhiteIcon}
                alt="Sign in"
                height={8}
                width={8}
                className="ml-3 group-hover:hidden"
              />
              <Image
                src={ExternalBlackIcon}
                alt="Sign in"
                height={8}
                width={8}
                className="ml-3 hidden group-hover:block"
              />
            </a>
          </Link>
        </div>
      </div>
      <Layout
        closeMenuPanel={closeMenuPanel}
        selectedSubMenu={selectedSubMenu}
      />
    </div>
  </div>
);

// First Level Panel
const FirstLevelPanel: React.FC<FirstLevelPanelProps> = ({
  closeMenuPanel,
  filteredMenu,
  handleMenuItemClick,
  selected,
  setSelected,
  selectedMobile,
}) => {
  const { properties: dealsPageTitleFFProps } =
    useSubscribeFF('deals-page-title');
  const dealsPageTitle =
    (dealsPageTitleFFProps?.menu?.value as string) ?? 'Hot Deals';

  return (
    <div className="flex h-full w-screen flex-col border-r bg-white shadow-xl sm:max-w-[75vw] md:max-w-[50vw] lg:max-w-[325px]">
      {/* Content */}
      <div className="hide-scrollbar mb-5 flex h-full flex-col overflow-y-scroll lg:pt-5">
        <div className="relative flex-1">
          {/* // Hard coded Menu items here */}
          <Link href={`/collections/hot-deals`}>
            <a
              onClick={() => {
                closeMenuPanel();
              }}
              className={cn('outline-none', {
                'hidden lg:block': selectedMobile?.length > 0,
              })}
            >
              <StaticFirstLevelMenuItem
                details={{ title: dealsPageTitle, icon: FireIcon }}
              />
            </a>
          </Link>
          {filteredMenu?.map((menuItem) => {
            const isExpanded = selected.menu?.handle === menuItem?.handle;

            return (
              <div className="flex flex-col">
                {menuItem.links ? (
                  <button
                    className="focus:outline-none"
                    type="button"
                    onClick={() => {
                      handleMenuItemClick(menuItem);
                    }}
                  >
                    <FirstLevelMenuItem
                      menuItem={menuItem}
                      isExpandable
                      isExpanded={isExpanded}
                      selected={selected}
                      setSelected={setSelected}
                      closeMenuPanel={closeMenuPanel}
                      selectedMobile={selectedMobile}
                    />
                  </button>
                ) : (
                  <Link href={getPageUrl(menuItem.handle)}>
                    <a
                      onClick={() => {
                        closeMenuPanel();
                      }}
                    >
                      <FirstLevelMenuItem
                        closeMenuPanel={closeMenuPanel}
                        menuItem={menuItem}
                        isExpandable={false}
                      />
                    </a>
                  </Link>
                )}
              </div>
            );
          })}
        </div>
      </div>
      {/*  Footer */}
      <div className="mx-4 h-[1px] bg-gray-500 sm:mx-5" />
      <div className="py-2">
        <FaqCta closeMenuPanel={closeMenuPanel} />
        <AccountCta />
        <BuybackCta closeMenuPanel={closeMenuPanel} />
      </div>
    </div>
  );
};

const PreviousView: React.FC<PreviousViewProps> = ({
  path,
  setPath,
  closeMenuPanel,
}) => {
  const backItem = path[path.length - 1];

  return (
    <div className="flex items-center justify-between gap-1.5 border-b bg-white px-5 py-4 font-bold">
      <button
        className="-ml-1 flex items-center hover:underline focus:outline-none"
        onClick={() => {
          setPath(backItem);
        }}
      >
        <Image
          src={ChevronRight}
          height={15}
          width={15}
          alt="Back"
          className="mr-2 rotate-180"
          priority
        />
        <span>{backItem.menuTitle}</span>
      </button>
      <Link href={`/collections/${backItem.handle}`}>
        <a
          className="hover:underline"
          onClick={() => {
            closeMenuPanel();
          }}
        >
          View All
        </a>
      </Link>
    </div>
  );
};

const MenuPanel: React.FC<MenuPanelProps> = ({ showMenu, setShowMenu }) => {
  const { data } = useSWR('/api/sidebar-menu');

  const filteredMenu: MenuItem[] = data || defaultMenu;

  // Selected menu item
  const [selected, setSelected] = useReducer(
    (
      state: { menu: MenuItem | null; subMenu: MenuItem | null },
      update: Partial<{ menu: MenuItem | null; subMenu: MenuItem | null }>,
    ) => ({
      ...state,
      ...update,
    }),
    { menu: null, subMenu: null },
  );

  // Mobile Logic
  const [selectedMobile, setSelectedMobile] = useReducer(
    (state: MenuItem[], item: MenuItem | null | MenuItem[]) => {
      if (item && isArray(item)) return item;

      if (item === null) return [];
      const existingItemIndex = state.findIndex(
        (el) => el.handle === item.handle,
      );

      if (state.length > 0 && existingItemIndex === state.length - 1) {
        const newState = [...state];

        newState.pop();

        return newState;
      }
      if (existingItemIndex >= 0) return [...state];

      return [...state, item];
    },
    [],
  );

  // Update Selected Menu on Data Change
  useEffect(() => {
    const updatedSelectedMenu =
      selected.menu != null
        ? filteredMenu.find((item) => item.handle === selected?.menu?.handle) ??
          filteredMenu[0]
        : null;

    const shouldUpdateSeletedSubMenu =
      selected.menu !== null &&
      selected.menu?.links?.every((item) => item.links);

    const updatedSelectedSubMenu = shouldUpdateSeletedSubMenu
      ? updatedSelectedMenu?.links?.find(
          (item) => item.handle === selected?.subMenu?.handle,
        ) ?? null
      : null;

    setSelected({ menu: updatedSelectedMenu, subMenu: updatedSelectedSubMenu });
  }, [filteredMenu]);

  // On menu item click
  const handleMenuItemClick = (type: 'mobile' | 'desktop', item: MenuItem) => {
    if (type === 'mobile') {
      if (item.links) {
        if (item.links.length === 1 && item.links[0].menuSectionHeader)
          setSelectedMobile(item.links[0]);
        else setSelectedMobile(item);
      }
    } else if (type === 'desktop') {
      if (
        selected.menu == null ||
        (selected.menu != null && item.handle !== selected.menu.handle)
      ) {
        const shouldUpdateSeletedSubMenu =
          isArray(item.links) &&
          item.links.length === 1 &&
          item.links[0].menuSectionHeader;

        setSelected({
          menu: item,
          subMenu: shouldUpdateSeletedSubMenu ? item?.links?.[0] : null,
        });
      } else {
        setSelected({ menu: null, subMenu: null });
      }
    }
  };

  // on menu panel close
  const closeMenuPanel = (val = false) => {
    setShowMenu(val);
    setTimeout(() => {
      setSelected({ menu: null, subMenu: null });
      setSelectedMobile(null);
    }, 600);
  };

  const isHolidaysSeason =
    dayjs().isBefore('2023-12-28', 'day') &&
    dayjs().isAfter('2023-11-30', 'day');

  return (
    <Transition.Root show={showMenu} as={Fragment}>
      <Dialog
        as="div"
        className="absolute z-50 text-xs"
        onClose={closeMenuPanel}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100 transform translate-x-0"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100 transform translate-x-0"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-700/50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-[100vw] lg:pr-20 xl:pr-0">
            <Transition.Child
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
              className="flex"
            >
              <Dialog.Panel className="pointer-events-auto relative flex h-[100dvh] flex-col">
                <Dialog.Title className="lg:hidden">
                  <div className="flex flex-col border-b bg-white px-5 py-4">
                    <div className="flex">
                      <button
                        type="button"
                        onClick={() => closeMenuPanel()}
                        className="group flex  items-center justify-start focus:outline-none"
                      >
                        <Image
                          src={XIcon}
                          alt="Close Panel"
                          height={16}
                          width={16}
                          className="transition duration-150 ease-in-out group-hover:scale-110"
                        />
                      </button>
                      <div className="flex flex-1 items-center justify-center hover:cursor-pointer lg:hidden">
                        <Link href="/" prefetch={false} legacyBehavior>
                          <a
                            aria-label="Link to Home"
                            id="icon-home-mobile"
                            onClick={() => closeMenuPanel()}
                            className="relative"
                          >
                            {isHolidaysSeason && (
                              <span className="absolute -right-2 top-0 h-[25px] scale-x-[-1] scale-y-[1]">
                                <Image
                                  src={ChristmasHat}
                                  alt="Reebelo Christmas"
                                  className="h-full w-full cursor-pointer object-cover"
                                  priority
                                />
                              </span>
                            )}
                            <Icons.LogoMobile />
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                  {selectedMobile.length > 0 && (
                    <PreviousView
                      path={selectedMobile}
                      setPath={setSelectedMobile}
                      closeMenuPanel={closeMenuPanel}
                      selectedMobile={selectedMobile}
                    />
                  )}
                </Dialog.Title>
                <div className="hide-scrollbar flex flex-1 overflow-y-scroll">
                  <div className="hidden lg:block">
                    <FirstLevelPanel
                      closeMenuPanel={closeMenuPanel}
                      filteredMenu={filteredMenu}
                      handleMenuItemClick={(item) =>
                        handleMenuItemClick('desktop', item)
                      }
                      selected={selected}
                      setSelected={setSelected}
                      selectedMobile={selectedMobile}
                    />
                  </div>
                  <div className="lg:hidden">
                    <FirstLevelPanel
                      closeMenuPanel={closeMenuPanel}
                      filteredMenu={
                        selectedMobile.length > 0
                          ? (selectedMobile[selectedMobile.length - 1]
                              .links as MenuItem[])
                          : filteredMenu
                      }
                      handleMenuItemClick={(item) =>
                        handleMenuItemClick('mobile', item)
                      }
                      selected={selected}
                      setSelected={setSelected}
                      selectedMobile={selectedMobile}
                      isMobile
                    />
                  </div>
                  <div className="hidden lg:block">
                    {selected.subMenu != null && (
                      <SecondLevelPanel
                        selectedSubMenu={selected.subMenu}
                        closeMenuPanel={closeMenuPanel}
                      />
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MenuPanel;
