/* eslint-disable react/forbid-dom-props */
import Image from 'next/future/image';
import Link from 'next/link';
import {
  CollectionCardProps,
  CollectionProps,
} from '@/components/HeaderMenu/MenuPanel/types';
import Badge from '@/components/HeaderMenu/MenuPanel/common/Badge';

const CollectionCard = ({ item, closeMenuPanel }: CollectionCardProps) => (
  <Link href={`/collections/${item.handle}`}>
    <a
      className="flex flex-col items-center justify-center text-sm font-bold hover:underline"
      onClick={() => {
        closeMenuPanel();
      }}
    >
      {item?.badge && (
        <Badge label={item.badge} className="absolute left-3 top-3" />
      )}{' '}
      <div className="flex h-[100px] w-[175px] cursor-pointer items-center justify-center">
        <span className="h-[100px] w-[175px]">
          {item.imageUrl ? (
            <Image
              src={item.imageUrl}
              height={150}
              width={175}
              alt={item.menuTitle}
              className="h-[100px] w-[175px] object-contain"
              priority
            />
          ) : (
            <div className="h-[100px] w-[175px] animate-pulse rounded-md bg-gray-500"></div>
          )}
        </span>
      </div>
      <h2 className="mt-3 text-center">{item.menuTitle}</h2>
    </a>
  </Link>
);

const Collection = ({ cardHeight, items, closeMenuPanel }: CollectionProps) => (
  <tr className="border-b last:border-b-0" style={{ height: cardHeight }}>
    <td className="relative p-5">
      <CollectionCard item={items[0]} closeMenuPanel={closeMenuPanel} />
    </td>
    {items[1] && (
      <td className="relative" style={{ height: cardHeight }}>
        <CollectionCard item={items[1]} closeMenuPanel={closeMenuPanel} />
      </td>
    )}
    {items[2] && (
      <td className="relative" style={{ height: cardHeight }}>
        <CollectionCard item={items[2]} closeMenuPanel={closeMenuPanel} />
      </td>
    )}
  </tr>
);

export default Collection;
