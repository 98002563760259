import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from '@headlessui/react';
import axios from '@lambda/commons/apis/axios';
import { CtaButton } from '@lambda/ui/src/Cta';
import Loading from '@lambda/ui/src/Loading';
import settings from '@/settings/index';
import type { ApiResponse } from '@/api/zipcode/state';
import { ZipcodeStateData } from '@/lib/use-customer-zipcode';

export default function DeliveryByPopup(props: {
  isOpen: boolean;
  onClose: () => void;
  onStateNameChange: (data: ZipcodeStateData) => void;
}) {
  const { isOpen, onStateNameChange, onClose } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [hasError, setHasError] = useState(false);
  const isCAStore = settings.store === 'reebelo-ca';
  const [isLoading, setIsLoading] = useState(false);

  const submitForm = async () => {
    if (inputRef.current) {
      const zipcode = inputRef.current.value;

      setHasError(false);
      if (zipcode === '') setHasError(true);

      // zipcode validation
      try {
        setIsLoading(true);
        const response = await axios.get<ApiResponse>(
          `/api/zipcode/state?zipcode=${zipcode}`,
        );

        if (response.data.error || response.data.state == null) {
          setIsLoading(false);
          setHasError(true);

          return;
        }
        const { stateName, state } = response.data.state;

        onStateNameChange({ stateName, state, zipcode });
        onClose();
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        setHasError(true);
      }
    }
  };

  useEffect(() => {
    const keyDownHandler = (e: any) => {
      if (e.key === 'Enter') {
        if (isOpen) {
          e.preventDefault();
          submitForm();
        }
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [isOpen]);

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      onClose={onClose}
      open={isOpen}
    >
      <div className="flex h-full items-center justify-center p-0 text-center lg:h-full lg:p-4">
        <Dialog.Overlay className="fixed inset-0 bg-gray-700 opacity-50" />

        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="inline-block h-screen align-middle "
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div className="relative z-50  mx-auto inline-block h-auto w-[92vw] rounded-[5px] bg-white p-5 xs:w-[90vw] xs:p-6 sm:static sm:w-[80vw] lg:w-[50vw] lg:shadow-xl xl:h-auto 2xl:w-[40vw]">
          <div className="">
            <button
              onClick={onClose}
              type="button"
              className="btn-close absolute top-3 right-2 h-8 w-8 bg-white text-red opacity-100 xs:top-2 xs:right-3 sm:top-3 md:top-5 md:right-5"
              aria-label="Close"
            ></button>
            <div className="text-gray-700">
              <h2 className="mb-1 text-left text-lg font-bold xs:mb-2 xs:text-xl">
                Choose your location
              </h2>
              <p className="pr-6 text-left text-sm !leading-tight xs:pr-0 xs:text-base">
                Please enter your location so we can provide more accurate
                shipping estimates
              </p>
              <div className="mt-3 flex w-full xs:mt-4 sm:mt-6">
                <div className="w-[70%]">
                  <input
                    className="w-full rounded-sm border border-solid border-gray-500 p-3 text-gray-700 outline-1 outline-gray-700 xs:p-3 "
                    placeholder={`Enter your ${
                      isCAStore ? 'postal code' : 'zipcode'
                    }`}
                    type={isCAStore ? 'text' : 'number'}
                    ref={inputRef}
                  />
                  {hasError && (
                    <div className="mt-1 w-full text-left text-sm leading-tight text-red xs:text-base">
                      {`Please enter a valid ${
                        isCAStore ? settings.country : settings.country_code
                      } ${isCAStore ? 'postal code' : 'zipcode'}`}
                    </div>
                  )}
                </div>
                <CtaButton
                  className="enabled:hover:bg-gray-700 enabled:hover:text-white enabled:hover:opacity-100 group relative !my-0 ml-2 flex !h-full w-[30%] items-center justify-center self-start rounded-full border border-gray-700 py-4 px-4 text-center text-xs font-bold text-gray-700 duration-300 ease-in-out xxs:!h-full xxs:p-2 xs:ml-4 xs:!h-full xs:p-3 sm:p-3 sm:px-5"
                  variant="white"
                  onClick={() => submitForm()}
                  disabled={isLoading}
                >
                  {isLoading ? <Loading /> : 'Apply'}
                </CtaButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
